import { Progress } from "@radix-ui/react-progress"
import React, {useState, useEffect} from "react"
import { SimpleProgress } from "@/components/simple-progress"

type Props = {}

const UploadingDocs = (props: Props) => {
    const [progress, setProgress] = useState(0);
    const [loadingText, setLoadingText] = useState<string | undefined>();

    const loadingTexts = [
        "Your file is taking a ride on the upload express...",
        "Hang on! We're getting things ready...",
        "Wait a second! Did we pack the file's lunch?...",
        "Almost there! Just crossing the T's and dotting the I's...",
        "Any moment now! Don't shut the door on us just yet...",
        "Done! Your file reached its destination. Thanks for sticking with us!"
    ];
    
    useEffect(() => {
        // Divide progress into equal portions based on the length of loadingTexts
        const portion = 100 / loadingTexts.length;
        // Calculate the index of the loadingText based on the current progress
        const index = Math.floor(progress / portion);
        // Make sure index stays within the bounds of the loadingTexts array
        const boundedIndex = Math.min(index, loadingTexts.length - 1);
        setLoadingText(loadingTexts[boundedIndex]);
    }, [progress]);

    useEffect(() => {
        const interval = setInterval(() => {
          setProgress((prev) => {
            let nextProgress;
            if (Math.random() < 0.1){
              nextProgress = prev + 2;
            } else {
              nextProgress = prev + 0.5;
            }
      
            // Make sure progress does not exceed 100
            nextProgress = Math.min(nextProgress, 100);
      
            console.log('Updated progress: ', nextProgress); // Logging the updated progress value
            return nextProgress;
          });
        }, 600);
        return () => clearInterval(interval);
      }, []);

    return (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[70vw] md:w-[80vw] flex flex-col items-center">
            <img src='/uploading.gif' className="w-96 h-96" alt="Uploading Docs"/>
            <SimpleProgress value={progress} />
            <h1 className="mt-2 text-xl">{loadingText}</h1>
        </div>
    )
}

export default UploadingDocs
