import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";
// import { CommandMenu } from "@/components/command-menu";
import React from 'react'
import { AuthContext } from '@/authentication/auth-wrapper'
import { Icons } from "@/components/icons";
import { MainNav } from "@/components/structure/main-nav";
import { MobileNav } from "@/components/structure/mobile-nav";
import { UserNav } from "@/components/user-nav";
import { ModeToggle } from "@/components/mode-toggle";
import { buttonVariants } from "@/components/ui/button";
import { Search } from "@/components/search";


export function SiteHeader() {

  const auth = React.useContext(AuthContext);

  if(!auth) {
    throw new Error("AuthContext is undefined");
  }

  const { user } = auth;
  
  return (
    <header className="supports-backdrop-blur:bg-background/60 sticky top-0 z-40 w-full border-b bg-background/95 backdrop-blur">
      <div className="container flex h-14 items-center min-w-[346px]">
        <MainNav />
        <MobileNav />
        <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
          <nav className="flex items-center space-x-2 overflow-x-auto">
            <Search />
            <ModeToggle />
            <UserNav/>
          </nav>
        </div>
      </div>
    </header>
  );
}
