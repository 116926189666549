import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/icons";
import { AuthContext } from "@/authentication/auth-wrapper";
import { nav } from "@/components/structure/navigation";

export function MainNav() {
  const location = useLocation();
  const auth = React.useContext(AuthContext);

  if(!auth) {
    throw new Error("AuthContext is undefined");
  }
  
  const { user, logout } = auth;

  return (
    <div className="mr-4 hidden md:flex">
      <Link to="/" className="mr-6 flex items-center space-x-2">
        <img width="40" height="40" className="rounded-lg" src="/ReadMy.AI_logo.png"/>
        <span className="hidden font-bold sm:inline-block">
          {siteConfig.name}
        </span>
      </Link>
      <nav className="flex items-center space-x-6 text-sm font-medium">
        {nav.map((link, i) => {
          if ((link.isMenu && !link.isPrivate || (link.isMenu && link.isPrivate && user?.isAuthenticated))) {
            return (
              <Link
                key={i}
                to={link.path}
                className={cn(
                  "transition-colors hover:text-foreground/80",
                  location.pathname.startsWith(link.path)
                    ? "text-foreground"
                    : "text-foreground/60"
                )}
              >
                {link.name}
              </Link>
            );
          }
          return null;
        })}
        {user?.isAuthenticated ? (
          <button onClick={logout} className="transition-colors hover:text-foreground/80">
            Log Out
          </button>
        ) : (
          <Link
            to="/login"
            className="transition-colors hover:text-foreground/80"
          >
            Login
          </Link>
        )}
      </nav>
    </div>
  );
}
