// UploadBookDialog.js
import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import UploadingDocs from "./uploading-docs";





export type UploadBookParams = {
  file: File;
  title: string;
  category: string;
  onUploadProgress: (progress: number) => void;
};

interface Props {
  onUpload: (
    params: UploadBookParams,
  ) => Promise<void>; // Returns a Promise
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode; // Accept children prop
}

export default function UploadBookDialog({
  onUpload,
  children,
  isOpen,
  onClose,
}: Props) {
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState("");
  

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file =
      event.target.files && event.target.files.length > 0
        ? event.target.files[0]
        : null;

    if (file) {
      console.log(`Selected file name: ${file.name}`);
      console.log(`Selected file type: ${file.type}`);
      console.log(`Selected file size: ${file.size} bytes`);
      console.log(`Selected file size: ${selectedCategory} bytes`);
    } else {
      console.log("No file selected");
    }

    setFile(file);
  };

  // Capitalize helper function
  const capitalizeWords = (input: string) => {
    return input.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const handleUploadClick = async () => {
    if (file && title) {
      
      navigate("/uploading"); // Navigate to the uploading page
      await onUpload({
        file,
        title,
        category: selectedCategory,
        onUploadProgress: (progress: number) => {
          console.log(`Upload progress: ${progress}%`);
        },
      });
      // Navigate back to the home page (or wherever you want) when the upload finishes
      navigate("/");
    } else {
      console.log("Missing file or title"); 
    }
  };
  
  console.log("Current category: " + selectedCategory);
  if (showLoader) {
    return <UploadingDocs/>
  }
  return (
    <div>
      {isOpen && (
        <Dialog open={isOpen} onOpenChange={onClose}>
          <DialogTrigger asChild>{children}</DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Upload Book</DialogTitle>
              <DialogDescription>
                Please enter the information for the book you are uploading.
              </DialogDescription>
            </DialogHeader>

            <div className="grid w-full max-w-sm items-center gap-4">
              <Label>
                <div className="mb-2">
                  <small className="text-sm font-semibold leading-none">
                    Title:
                  </small>
                </div>
                <Input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(capitalizeWords(e.target.value))}
                />
              </Label>
              <Label>
                <div className="mb-2">
                  <small className="text-sm font-semibold leading-none">
                    Category:
                  </small>
                </div>
                <Select onValueChange={setSelectedCategory}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue
                      placeholder={selectedCategory || "Select a category"}
                    ></SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel></SelectLabel>
                      <SelectItem value="Textbook">Textbook</SelectItem>
                      <SelectItem value="Book">Book</SelectItem>
                      <SelectItem value="Document">Document</SelectItem>
                      <SelectItem value="Note">Note</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </Label>
              <Label>
                <div className="mb-2">
                  <small className="text-sm font-semibold leading-none">
                    File:
                  </small>
                </div>

                <Input type="file" onChange={handleFileChange} />
              </Label>
            </div>
            <DialogFooter>
              <Button onClick={handleUploadClick}>Upload</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
      
    </div>
  );
}
