import { Link } from "react-router-dom"; // replace this line
import { Command } from "lucide-react";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { UserAuthForm } from "@/authentication/components/user-auth-form";

export const metadata = {
  title: "Authentication",
  description: "Authentication forms built using the components.",
};

export default function LoginPage() {
  return (
    <>
      {/* ... */}
      <div className="container relative flex-col items-center justify-center grid lg:grid-cols-2 md:px-0 lg:max-w-none h-screen">
        <div
          className="relative h-full flex-col bg-black bg-cover bg-center bg-no-repeat p-10 text-white dark:border-r lg:flex"
          style={{
            backgroundImage: `url('https://readmyaistorage.blob.core.windows.net/site-images/hero_image1.png')`,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />
          <div className="relative z-20 mt-auto hidden lg:block">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;ReadMy.AI is my secret weapon for acquiring knowledge on
                the fly. Whether it's a dense technical manual or a lengthy
                novel, this app allows me to chat with any file and instantly
                extract the information I need.&rdquo;
              </p>
              <footer className="text-sm">-- Alexandra Johnson</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8" style={{ minWidth: "359px" }}>
          <div className="mx-auto flex w-full flex-col justify-center  space-y-6 md:w-[450px] lg:w-[600px]">
            <div className="relative z-20 flex justify-center items-center text-xl font-medium -mt-4 lg:-mt-6">
              <img
                src="./ReadMy.AI_logo.png"
                alt="ReadMyAI"
                className="mr-2  rounded-md h-10 w-10 lg:h-16 lg:w-16"
              />{" "}
              ReadMy.Ai
            </div>
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight lg:mt-8">Login</h1>
              <p className="text-sm text-muted-foreground">
                Enter credentials below
              </p>
            </div>
            <UserAuthForm formType="login" />
            <p className="text-md text-muted-foreground text-center mt-2">
              Not yet registered?{" "}
            </p>
            <Link
              to="/register"
              className={cn(
                buttonVariants({ variant: "ghost" }),
                "relative text-md"
              )}
            >
              Create Account
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
