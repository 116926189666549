import DashboardPage  from '@/dashboard/dashboard'
import LoginPage from '@/authentication/login'
import RegisterPage from '@/authentication/register'
import MyLibraryPage from '@/my-library/my-library'
import SettingsLayout from '@/settings/layout'
import SettingsProfilePage from '@/settings/profile/profile'
import SettingsAccountPage from '@/settings/account/account'
import { BooksLibrary } from '@/my-library/components/books/books-library'
import { NotFound } from '@/error-handlers/not-found'
import TestComponent from '../transition-tester'
import UploadingDocs from '@/my-library/components/uploading-docs'

export const nav = [
    {path: "/", name:"Home", element: <MyLibraryPage/>, isMenu:true, isPrivate: true },
    {path: "/login", name:"Login", element: <LoginPage/>, isMenu:false, isPrivate: false },
    {path: "/register", name:"Create Account", element: <RegisterPage/>, isMenu:false, isPrivate: false },
    {path: "/my-library", name:"My Library", element: <MyLibraryPage/>, isMenu:true, isPrivate: true },
    {path: "/settings/profile", name:"Profile", element: <SettingsLayout><SettingsProfilePage /></SettingsLayout>, isMenu:false, isPrivate: true },
    {path: "/settings/account", name:"Account", element: <SettingsLayout><SettingsAccountPage /></SettingsLayout>, isMenu:false, isPrivate: true },
    {path: "/chat/:bookTitle", name:"Chat", element: <BooksLibrary/>, isMenu:false, isPrivate:true},
    {path: "/error", name:"Error Page", element: <NotFound/>, isMenu:false, isPrivate:false},
    {path: "/test-page", name: "Test Page", element: <TestComponent/>, isMenu:false, isPrivate:false},
    {path: "/uploading", name:"Uploading Docs", element: <UploadingDocs />, isMenu:false, isPrivate:true},
    {path: "/dashboard", name:"Dashboard", element: <DashboardPage/>, isMenu:false, isPrivate: false },
    
    
    
    
]