import React, { ReactNode, createContext, useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { User } from "@/models/auth-types/User";

export interface AuthContextType {
  user: User | null;
  login: (username: string, password: string) => Promise<any>;
  logout: () => void;
  signup: (username: string, password: string) => Promise<any>;
}

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

interface AuthWrapperProps {
  children: ReactNode; // This line means "expect a children prop that is a React node"
}
export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate(); // Get access to the history object
  // This useEffect will run when the component mounts.
  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Replace with your actual API URL
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}get_current_user/`,
          {
            headers: {
              Authorization: `Token ${Cookies.get("auth_token")}`,
            },
          }
        );

        // If the request is successful, set the user data in your state
        setUser(response.data);
      } catch (error) {
        // Handle error...
        console.error("Error during user data fetching:", error);
      }
    };

    // If there's a token, fetch the user data
    if (Cookies.get("auth_token")) {
      fetchUserData();
    }
  }, []);

  const login = async (username: string, password: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}login/`,
        { username, password },
        { withCredentials: true }
      );
      const user = response.data;

      setUser(user);
      console.log(user); // Add this line
      // Set the cookie with user data
      // Set the cookie with user token
      Cookies.set("auth_token", user.token, { sameSite: "none", secure: true });

      return user;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  };

  const signup = async (username: string, password: string) => {
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}signup/`, {
        username,
        password,
      });
      const user = response.data;
      setUser(user);

      navigate('/login'); // Navigate to the login page
      return user;
    } catch (error) {
      console.error("Error signing up:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      // Call the logout API endpoint
      await axios.post(
        `${process.env.REACT_APP_API_URL}logout/`,
        {},
        {
          headers: {
            Authorization: `Token ${Cookies.get("auth_token")}`,
          },
        }
      );

      // If the API call was successful, remove the token cookie
      Cookies.remove("auth_token");
      setUser(null);
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, signup }}>
      {children}
    </AuthContext.Provider>
  );
};
