import * as React from "react";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { AuthContext } from "@/authentication/auth-wrapper";
import { useNavigate } from "react-router-dom";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  formType: "login" | "register";
}

export function UserAuthForm({ className, formType, ...props }: UserAuthFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({userName: "", password: ""});
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  if (!auth) {
    throw new Error("AuthContext is undefined");
  }

  const { login, signup, user } = auth;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (formType === "register") {
        await signup(formData.userName, formData.password);
      } else {
        await login(formData.userName, formData.password);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An error occurred");
      }
      setIsLoading(false);
    }
    
  };

  // Add this useEffect hook just before the return statement
  React.useEffect(() => {
    if (user && user.isAuthenticated) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="userName">
              Username (Email)
            </Label>
            <Input
              id="userName"
              value={formData.userName}
              placeholder="Email"
              onChange={handleInputChange}
              disabled={isLoading}
            />
            <Label className="sr-only" htmlFor="password">
              Password
            </Label>
            <Input
              id="password"
              type="password"
              value={formData.password}
              placeholder="*********"
              onChange={handleInputChange}
              disabled={isLoading}
            />
          </div>
          <Button disabled={isLoading}>
            {/* ... */}
            {formType === "register" ? "Sign Up" : "Sign In"}
          </Button>
          {/* ... */}
        </div>
      </form>
      
    </div>
  );
}
