import React from 'react'
import { SiteHeader } from "./structure/site-header";
import { SiteFooter } from "./structure/site-footer";
import { RenderRoutes } from "./structure/render-navigation";
import { AuthContext } from '@/authentication/auth-wrapper'


export const MainApp = () => {
  const auth = React.useContext(AuthContext);

  if(!auth) {
    throw new Error("AuthContext is undefined!")
  }
  const { user } = auth;
  
  return (
    <div className="flex flex-col min-h-screen">
    {user?.isAuthenticated && <SiteHeader/>}
      <main className="flex-grow">
        <RenderRoutes />
      </main>
    {user?.isAuthenticated && <SiteFooter/>}
  </div>
  );
}
