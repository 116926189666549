import React, { useState, useEffect, useRef } from "react";
import { askQuestion, fetch_chat_history, startChat } from "@/api/chat";
import { X, SendIcon } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import Spinner from "react-spinkit";

interface ChatModalProps {
  isOpen: boolean;
  closeModal: () => void;
  bookTitle: string | null;
  book_id: number | null;
}

type ChatMessage = {
  text: string;
  isUser: boolean;
};

interface ChatHistoryItem {
  message: string;
  role: string;
  // include other properties as needed
}

const ChatModal: React.FC<ChatModalProps> = ({
  isOpen,
  closeModal,
  bookTitle,
  book_id,
}) => {
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState<ChatMessage[]>([]);
  const [questionError, setQuestionError] = useState<string | null>(null);
  const [shouldRender, setShouldRender] = useState(isOpen);
  bookTitle = bookTitle || "default";
  const validBookTitle = bookTitle || "default";
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  // Create a ref for the chat box
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // When the component mounts, start a chat
    const initiateChat = async () => {
      if (book_id !== null) {
        try {
          await startChat(book_id);
          console.log("Chat started successfully!");
        } catch (error) {
          console.error("Error starting chat:", error);
        }
      }
    };

    initiateChat();
  }, [book_id]);

  useEffect(() => {
    // Whenever the book title changes, clear the chat
    setChat([]);
  }, [bookTitle]);

  // Make sure the chat box always shows the latest messages
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShouldRender(true);
      }, 200); // delay before opening the modal
    } else {
      setTimeout(() => {
        setShouldRender(false);
      }, 200); // delay before closing the modal
    }
  }, [isOpen]);

  async function handleQuestion(book_id: number, question: string) {
    try {
      setIsLoading(true);
      const response = await askQuestion(book_id, question);
      let answer = response.answer; // extract answer from the response
      answer = answer
        .split("\n")
        .map((line: string) => `<li class="mb-2">${line}</li>`) // Wrap each line inside <li> tags with a bottom margin of 2
        .join("");
      answer = `<ul>${answer}</ul>`; // Wrap the entire response inside <ul> tags
      setIsLoading(false);
      return answer;
    } catch (error) {
      setIsLoading(false);
      // Display the error message for 5 seconds, then hide it
      setQuestionError("Error asking the question. Please try again.");
      setTimeout(() => setQuestionError(null), 5000);
      throw error; // let the caller know that an error occurred
    }
  }

  const submitMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("book_id:", book_id); // debug log here
    if (book_id) {
      // Add user's message and a loading placeholder
      setChat((prevChat) => [
        ...prevChat,
        { text: message, isUser: true },
        {
          text: "<div className='loading'><Spinner name='circle' color='#00BFFF' /></div>",
          isUser: false,
        }, // Loading placeholder
      ]);
      try {
        setIsLoading(true); // Set loading to true before making the request
        const answer = await handleQuestion(book_id, message);
        // Remove the loading placeholder and add the AI's response
        setChat((prevChat) => [
          ...prevChat.slice(0, -1), // Exclude the last item (loading placeholder)
          { text: answer, isUser: false },
        ]);
        setMessage("");
      } catch (error) {
      } finally {
        setIsLoading(false); // Always set loading back to false, whether the request was successful or not
      }
    }
  };

  const fetchChatHistory = async (book_id: number) => {
    try {
      const chatHistory: ChatHistoryItem[] = await fetch_chat_history(book_id);
      console.log('Chat History:', chatHistory); // Log the response
      const transformedChatHistory: ChatMessage[] = chatHistory.map((chatItem) => ({
        text: chatItem.message, // Extracting the message
        isUser: chatItem.role === 'user', // Identifying if it's a user's message
      }));
      setChat(transformedChatHistory);
    } catch (error) {
      // handle error
    }
  };

  useEffect(() => {
    if (isOpen && book_id) {
      fetchChatHistory(book_id);
    }
  }, [isOpen, book_id]);
  if (!shouldRender) return null;

  return (
    <div className="fixed inset-0 overflow-y-auto z-10 pt-16 pb-16 flex justify-center ">
      <div className="flex justify-center text-center items-center sm:block p-0 w-auto">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block content-center h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
         className={`inline-block content-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 content-center w-[360px] md:w-[660px]  ${
          isOpen ? "duration-500 delay-200" : "duration-200"
        }`}
        
        >
          {/* <div className="rounded-xl border bg-card text-card-foreground shadow"> */}
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="font-semibold mb-4 text-center leading-none tracking-tight">
                {validBookTitle}
              </h3>
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                >
                  <X />
                </button>
              </div>
            </div>
            <div className="p-6 pt-0 overflow-y-auto max-h-[50vh]">
              <div className="space-y-4">
              {chat.map((messageObject, index) => {
          const isUserMessage = messageObject.isUser;
          const flexClass = isUserMessage
            ? "flex-row"
            : "flex-row-reverse";
          const bubbleClass = isUserMessage
            ? "flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-primary text-primary-foreground"
            : "flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted";

          return (
            <div key={index} className={`p-1 flex ${flexClass} items-center`}>
              <div className={`p-1 rounded-lg max-w-lg ${bubbleClass} mb-4`}>
                {messageObject.text && messageObject.text.includes("<div className='loading'>") ? (
                  <>
                  <Spinner name="pulse" color="#7C3AED" />
                  </>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: messageObject.text,
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
        <div ref={chatEndRef} />
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <form onSubmit={submitMessage} className="flex p-2 w-full">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="flex h-9 w-full mr-2 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 flex-1"
                  placeholder="Type your message here..."
                />
                <button
                  type="submit"
                  className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-9 w-9"
                >
                  <SendIcon size={25} color="#ffffff" strokeWidth={1.5} />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
  

  
};

export default ChatModal;
