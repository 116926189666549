export const siteConfig = {
    name: "ReadMy.Ai",
    url: "reginaldburrus@netlify.app",
    ogImage: "https://readmyaistorage.blob.core.windows.net/site-images/ReadMy.AI_logo.png",
    description:
      "Revolutionize learning with ReadMy.AI: Transforming education through AI-powered insights.",
    links: {
      twitter: "https://twitter.com/devandbeyond",
      github: "https://github.com/regdamon25/",
    },
  }
  
  export type SiteConfig = typeof siteConfig