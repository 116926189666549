import { Book } from "@/models/Book";

export const listenNowBooks: Book[] = [
  {
    id: 34,
    title: "The Five-Star Weekend",
    authors: ["Elin Hilderbrand"],
    file_type: "PDF",
  },
  {
    id: 35,
    title: "Happy Place",
    authors: ["Emily Henry"],
    file_type: "PDF",
  },
  {
    id: 36,
    title: "Lessons In Chemistry",
    authors: ["Bonnie Garmus"],
    file_type: "DOCX",
  },
  {
    id: 37,
    title: "It Ends With Us",
    authors: ["Colleen Hoover"],
    file_type: "CSV",
  },
];

export const madeForYouBooks: Book[] = [
  // {
  //   id: 38,
  //   title: "Fourth Wing",
  //   authors: ["Rebecca Yarros"],
  //   file_type: "EPUB",
  // },
  // {
  //   id: 39,
  //   title: "Outlive",
  //   authors: ["Peter Attia", "Bill Gifford"],
  //   file_type: "EPUB",
  // },
  // {
  //   id: 40,
  //   title: "The Wagner",
  //   authors: ["David Grann"],
  //   file_type: "DOCX",
  // },
  // {
  //   id: 41,
  //   title: "1964",
  //   authors: ["Paul McCartney"],
  //   file_type: "DOCX",
  // },
  // {
  //   id: 42,
  //   title: "I'm Glad My Mom Died",
  //   authors: ["Jennette McCurdy"],
  //   file_type: "PPTX",
  // },
  // {
  //   id: 43,
  //   title: "Sixty-One",
  //   authors: ["Chris Paul", "Michael Wilbon"],
  //   file_type: "PPTX",
  // },
];
