import React from 'react';

type SimpleProgressProps = {
  value: number; // Represents progress as a percentage (0 - 100)
};

export const SimpleProgress: React.FC<SimpleProgressProps> = ({ value }) => {
  return (
    <div className="w-80 h-2 bg-gray-200 rounded-lg overflow-hidden">
      <div 
        className="h-full bg-blue-500 transition-all duration-500" 
        style={{ width: `${value}%` }}
      />
    </div>
  );
};