// src/api/chat.ts
import axios from "axios";
import Cookies from "js-cookie";

export async function askQuestion(book_id:number, question: string) {
  const token = Cookies.get('auth_token');
  const chat_session_id = Cookies.get('chat_session_id');
  console.log("Here is the book Id:", book_id )
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}ask_question/`,
      {
        book_id: book_id,
        question: question,
        chat_session_id: chat_session_id, // Pass the chat session id to the server
      },
      {
        headers: {
          Authorization: `Token ${token}`,
          
        },
      }
    );

    // Save the chat session id in a cookie
    console.log(response.data);  // Add this line
    Cookies.set('chat_session_id', response.data.chat_session_id);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error response:", error.response);
    }
    console.error("Error asking a question:", error);
    throw error;
  }
}

export async function startChat(book_id: number) {
  const token = Cookies.get('auth_token');
  try {
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}start_chat/${book_id}/`,
          {}, // No body for this POST request
          {
              headers: {
                  Authorization: `Token ${token}`,
              },
          }
      );

      // Save the chat session id in a cookie
      Cookies.set('chat_session_id', response.data.chat_session_id);

      return response.data;
  } catch (error) {
      console.error("Error starting chat:", error);
      throw error;
  }
}


export async function fetch_chat_history(book_id: number) {
    // Getting the token from the cookie
    const token = Cookies.get('auth_token');
    
    // Getting the chat session id from the cookie
    const chat_session_id = Cookies.get('chat_session_id');

    // Checking if the token and chat_session_id exists
    if (!token || !chat_session_id) {
      throw new Error("Auth token or chat session id is not available.");
    }

    try {
      const response = await axios.get(
        
        `${process.env.REACT_APP_API_URL}chat_history/${book_id}/${chat_session_id}/`,
        
        { 
          headers: { 
            Authorization: `Token ${token}` 
          },
        }
      );
  

        // Check if the response status code is outside the 2xx range
        if (response.status < 200 || response.status >= 300) {
            console.error(
                `Error fetching chat history: Server responded with status code ${response.status}`
            );
            throw new Error(`Server responded with status code ${response.status}`);
        }
        
        return response.data;

    } catch (error) {
        console.error("Error fetching chat history:", error);
        throw error; // re-throw the error to be handled by the caller if needed
    }
}

  
        
    
