import { Link, Route, Routes } from "react-router-dom";
import { useContext } from 'react'
import { AuthContext } from "@/authentication/auth-wrapper";
import { nav } from "@/components/structure/navigation";
import RegisterPage from "@/authentication/register";
import LoginPage from "@/authentication/login"; 

export const RenderRoutes = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }

  const { user } = authContext;

  return (
    <Routes>
      {nav.map((r, i) => {
        if (r.isPrivate && user?.isAuthenticated) {
          return <Route key={i} path={r.path} element={r.element} />;
        } else if (!r.isPrivate) {
          return <Route key={i} path={r.path} element={r.element} />;
        } else return null; // Return null instead of false to avoid any warnings from React.
      })}
      { (!user || !user.isAuthenticated) && <Route path="*" element={<LoginPage />} /> }
      { (!user || !user.isAuthenticated) && <Route path="/register" element={<RegisterPage />} /> }
    </Routes>
  );
};



