import {
    LayoutGrid,
    Library,
    ListMusic,
    Mic2,
    Music,
    Files,
    Music2,
    PlayCircle,
    Radio,
    User,
    BookPlus,
    Newspaper,
    BookKey,
    ScrollText
  } from "lucide-react"
  import { Icons } from '@/components/icons'
  import { cn } from "@/lib/utils"
  import { Button } from "@/components/ui/button"
  import { ScrollArea } from "@/components/ui/scroll-area"
  
  import { Quizlist } from "../data/quiz-lists"
  import { Badge } from "@/components/ui/badge"

  
  interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
    quizlists: Quizlist[]
  }
  
  export function Sidebar({ className, quizlists }: SidebarProps) {
    return (
      <div className={cn("pb-12", className)}>
        <div className="space-y-4 py-4">
          <div className="px-3 py-2">
            <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
              Discover
            </h2>
            <div className="space-y-1">
              <Button variant="secondary" className="w-full justify-start">
                <PlayCircle className="mr-2 h-4 w-4" />
                Chat with video
                <Badge className="ml-2" variant="announcement">Coming Soon!</Badge>
              </Button>
              <Button variant="ghost" className="w-full justify-start">
                <LayoutGrid className="mr-2 h-4 w-4" />
                Chat with docs
              </Button>
              <Button variant="ghost" className="w-full justify-start">
                <Radio className="mr-2 h-4 w-4" />
                Quiz Generator
                <Badge className="ml-2" variant="announcement">Coming Soon!</Badge>

              </Button>
            </div>
          </div>
          <div className="px-3 py-2">
            <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
              Library
            </h2>
            <div className="space-y-1">
              <Button variant="ghost" className="w-full justify-start">
                <Newspaper className="mr-2 h-4 w-4" />
                Quizzes
                <Badge className="ml-2" variant="announcement">Coming Soon!</Badge>
              </Button>
              <Button variant="ghost" className="w-full justify-start">
                <BookPlus className="mr-2 h-4 w-4" />
                Books
              </Button>
              <Button variant="ghost" className="w-full justify-start">
                <Files className="mr-2 h-4 w-4" />
                Documents
              </Button>
              <Button variant="ghost" className="w-full justify-start">
                <BookKey className="mr-2 h-4 w-4" />
                Textbooks
              </Button>
              {/* <Button variant="ghost" className="w-full justify-start">
                <Library className="mr-2 h-4 w-4" />
                Albums
              </Button> */}
            </div>
          </div>
          <div className="py-2">
            <h2 className="relative px-7 text-lg font-semibold tracking-tight">
              Quiz Lists
              <Badge className="ml-2" variant="announcement">Coming Soon!</Badge>
            </h2>
            <ScrollArea className="h-[300px] px-1">
              <div className="space-y-1 p-2">
                {quizlists?.map((quizlist, i) => (
                  <Button
                    key={`${quizlist}-${i}`}
                    variant="ghost"
                    className="w-full justify-start font-normal"
                  >
                    <ScrollText className="mr-2 h-4 w-4" />
                    {quizlist}
                  </Button>
                ))}
              </div>
            </ScrollArea>
          </div>
        </div>
      </div>
    );
  }