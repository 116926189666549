import { ListMusic, PlusCircle } from "lucide-react"

import { cn } from "@/lib/utils"
import { AspectRatio } from "@/components/ui/aspect-ratio"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from "@/components/ui/context-menu"

import { Book } from "@/models/Book"
import { quizlists } from "../../data/quiz-lists"
import { getCurrentFilePlaceholder } from "@/api/books" // Import getFilePlaceholder


interface AlbumArtworkProps extends React.HTMLAttributes<HTMLDivElement> {
  book: Book
  aspectRatio?: "portrait" | "square"
  width?: number
  height?: number
  action?: React.ReactNode
}

export function AlbumArtwork({
  book,
  aspectRatio = "portrait",
  width,
  height,
  action,
  className,
  ...props
}: AlbumArtworkProps) {
  // Then, when you call `getFileKey` and `getFilePlaceholder`:
  
  const placeholder = getCurrentFilePlaceholder(book.file_type);
    return (
    <div className={cn("space-y-3", className)} {...props}>
      <ContextMenu>
        <ContextMenuTrigger>
          <div className="overflow-hidden rounded-md">
            <img
              src={placeholder}
              alt={book.title}
              width={width}
              height={height}
              className={cn(
                "h-auto w-auto object-cover transition-all hover:scale-105",
                aspectRatio === "portrait" ? "aspect-[3/4]" : "aspect-square"
              )}
            />
          </div>
        </ContextMenuTrigger>
        <ContextMenuContent className="w-40">
          <ContextMenuItem>Add to Library</ContextMenuItem>
          <ContextMenuSub>
            <ContextMenuSubTrigger>Add to Playlist</ContextMenuSubTrigger>
            <ContextMenuSubContent className="w-48">
              <ContextMenuItem>
                <PlusCircle className="mr-2 h-4 w-4" />
                New Playlist
              </ContextMenuItem>
              <ContextMenuSeparator />
              {quizlists.map((quizlist) => (
                <ContextMenuItem key={quizlist}>
                  <ListMusic className="mr-2 h-4 w-4" /> {quizlist}
                </ContextMenuItem>
              ))}
            </ContextMenuSubContent>
          </ContextMenuSub>
          <ContextMenuSeparator />
          <ContextMenuItem>Play Next</ContextMenuItem>
          <ContextMenuItem>Play Later</ContextMenuItem>
          <ContextMenuItem>Create Station</ContextMenuItem>
          <ContextMenuSeparator />
          <ContextMenuItem>Like</ContextMenuItem>
          <ContextMenuItem>Share</ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>
      <div className="space-y-1 text-sm">
        <h3 className="font-semibold leading-none">{book.title}</h3>
        {action}
        <p className="text-xs text-muted-foreground">{book.authors}</p>
      </div>
    </div>
  )
}