import React from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { X } from "lucide-react";

const TestComponent: React.FC = () => {
  return (
    <div className="fixed inset-0 overflow-y-auto z-10 pt-16 pb-16 flex justify-center ">
      <div className="flex justify-center text-center items-center sm:block p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block content-center h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        {/* Rest of your chat modal content */}
        <div
          className={`inline-block content-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 content-center w-[360px] md:w-[660px]`}
        >
          
          <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="font-semibold mb-4 text-center leading-none tracking-tight">
                <h4>Book Title</h4>
              </h3>
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  
                  className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                >
                  <X />
                </button>
              </div>
            </div>
          
           
          <div className="p-6 pt-0 overflow-y-auto max-h-[50vh]">
            <div className="space-y-4">
              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                Hi, how can I help you today? Hey, I'm having trouble with my account. Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                What seems to be the problem?
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                I can't log in.
              </div>
              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                Hi, how can I help you today? Hey, I'm having trouble with my account. Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                What seems to be the problem?
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                I can't log in.
              </div>
              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                Hi, how can I help you today? Hey, I'm having trouble with my account. Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                What seems to be the problem?
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                I can't log in.
              </div>
              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                Hi, how can I help you today? Hey, I'm having trouble with my account. Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                What seems to be the problem?
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                I can't log in.
              </div>
              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                Hi, how can I help you today? Hey, I'm having trouble with my account. Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                What seems to be the problem?
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                I can't log in.
              </div>
              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                Hi, how can I help you today? Hey, I'm having trouble with my account. Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                What seems to be the problem?
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                I can't log in.
              </div>
              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                Hi, how can I help you today? Hey, I'm having trouble with my account. Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                Hey, I'm having trouble with my account.
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm bg-muted">
                What seems to be the problem?
              </div>

              <div className="flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ml-auto bg-primary text-primary-foreground">
                I can't log in.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestComponent;
