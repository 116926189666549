import { Link } from "react-router-dom"; // replace this line
import { Command } from "lucide-react";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { UserAuthForm } from "@/authentication/components/user-auth-form";

export const metadata = {
  title: "Authentication",
  description: "Authentication forms built using the components.",
};

export default function RegisterPage() {
  return (
    <>
      {/* ... */}
      <div className="container relative flex-col items-center justify-center grid lg:grid-cols-2 md:px-0 lg:max-w-none h-screen">
        <div
          className="relative h-full flex-col bg-black bg-cover bg-center bg-no-repeat p-10 text-white dark:border-r lg:flex"
          style={{
            backgroundImage: `url('https://readmyaistorage.blob.core.windows.net/site-images/hero_image1.png')`,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />
          {/* <div className="absolute inset-0 bg-zinc-900" /> */}
          <div className="relative z-20 flex items-center text-lg font-medium">
            <img
              src="./ReadMy.AI_logo.png"
              alt="ReadMyAI"
              className="mr-2 rounded-md h-6 w-6"
            />{" "}
            ReadMy.Ai
          </div>
          <div className="relative z-20 mt-auto hidden lg:block">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;This library has saved me countless hours of work and
                helped me deliver stunning designs to my clients faster than
                ever before.&rdquo;
              </p>
              <footer className="text-sm">-- Timothy Clark</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8" style={{ minWidth: "359px" }}>
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 md:w-[450px]">
            <div className="relative z-20 flex justify-center items-center text-xl font-medium -mt-4 lg:-mt-6">
              <img
                src="./ReadMy.AI_logo.png"
                alt="ReadMyAI"
                className="mr-2 rounded-md h-10 w-10 lg:h-16 lg:w-16 "
              />{" "}
              ReadMy.Ai
            </div>
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight lg:mt-8">
                Create an account
              </h1>

              {/* <p className="text-sm text-muted-foreground">
                Enter your email below to create your account
              </p> */}
            </div>
            <UserAuthForm formType="register" />
            <p className="text-md text-muted-foreground text-center">
              Already have an account?{" "}
            </p>
            <Link
              to="/login"
              className={cn(
                buttonVariants({ variant: "ghost" }),
                "relative text-md"
              )}
            >
              Login
            </Link>
            <p className="px-8 text-center text-sm text-muted-foreground ">
              By clicking continue, you agree to our{" "}
              <Link
                to="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
