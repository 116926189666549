import axios from "axios";
import { Book } from "@/models/Book";
import Cookies from 'js-cookie'


export async function uploadBook(
  file: File,
  title: string,
  category: string,
  onUploadProgress: (progress: number) => void
) {
  // Ensure the file type is either PDF, Word, Epub, Excel, CSV, TXT, ENEX, or PPTX
  const allowedFileTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/epub+zip",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "text/plain",
    "application/enex+xml",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/xml", // or "application/xml", whatever the browser recognizes .enex files as
  ];
  
  if (!allowedFileTypes.includes(file.type)) {
    throw new Error(
      `Unsupported file type. The file must be a PDF, Word document, Epub, Excel, CSV, TXT, ENEX, or PPTX. Received: ${file.type}`
    );
  }

  const formData = new FormData();
  const formKey = getFormKey(file.type);
  formData.append(formKey , file);
  formData.append("title", title);
  formData.append("category", category);

  
  const token = Cookies.get('auth_token');
  
  console.log("Form data before sending request:", formData);
  
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}process_file/`,
      formData,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onUploadProgress(percentCompleted);
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading the book:", error);
    throw error; // re-throw the error so it can be handled by the caller if needed
  }
}

export async function fetchUserBooks(userId: number): Promise<Book[]> {
  console.log(`REACT_APP_API_URL is: ${process.env.REACT_APP_API_URL}`);

  if (userId === undefined) {
    throw new Error('userId is undefined');
  }
  const token = Cookies.get('auth_token');
  if (!token) {
    throw new Error("You do not have permission to view books.");
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}get_user_books/${userId}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(
        `Error fetching books: Server responded with status code ${response.status}`
      );
      throw new Error(`Server responded with status code ${response.status}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching books:", error);
    throw error;
  }
}


export async function deleteBook(book_id: number) {
  const token = Cookies.get('auth_token');
  if (!token) {
    // handle situation when user is not logged in.
    // maybe redirect to login page or show appropriate message to user
    throw new Error("You do not have permission to delete this book.");
  }
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}delete_single_book/${book_id}/`, {
      headers: {
        Authorization: `Token ${token}`,
        
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting book:", error);
    throw error;
  }

}

export function getFormKey(fileType: string) {
  switch (fileType) {
    case "application/pdf":
      return "pdf_file";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "word_file";
    case "application/epub+zip":
      return "epub_file";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xlsx_file";
    case "text/csv":
      return "csv_file";
    case "text/plain":
      return "txt_file";
    case "text/xml": // or "application/xml"
      return "enex_file";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "pptx_file";
    default:
      throw new Error(`Unsupported file type: ${fileType}`);
  }
}



export function getCurrentFilePlaceholder(fileType: string) {
  return `https://readmyaistorage.blob.core.windows.net/site-images/${fileType}.png`;
}


