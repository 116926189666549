import React from 'react'
import { siteConfig } from "@/config/site"
import { AuthContext } from '@/authentication/auth-wrapper'

export function SiteFooter() {
  const auth = React.useContext(AuthContext);
  
  if(!auth){
    throw new Error("AuthContext is undefined");
  }

  const { user } = auth;
  
  
  return (
    <footer className="py-6 md:px-8 md:py-0">
      <div className="container flex flex-col items-center justify-between gap-4 md:h-24 md:flex-row">
        <p className="text-center text-sm leading-loose text-muted-foreground md:text-left">
          Built by{" "}
          <a
            href={siteConfig.links.twitter}
            target="_blank"
            rel="noreferrer"
            className="font-medium underline underline-offset-4"
          >
            Dev&Beyond LLC
          </a>
        </p>
      </div>
    </footer>
  )
}