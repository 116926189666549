import React, { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { UploadBookParams } from "@/my-library/components/upload-book-dialog";
import { BookEmptyPlaceholder } from "@/my-library/components/books/book-empty-placeholder";
import { TextbookEmptyPlaceholder } from "./components/textbooks/textbook-empty-placeholder";
import { DocumentEmptyPlaceholder } from "./components/documents/document-empty-placeholder";
import { NotesEmptyPlaceholder } from "@/my-library/components/notes/notes-empty-placeholder";
import { listenNowBooks } from "@/my-library/data/books";
import { Sidebar } from "./components/sidebar";
import UploadBookDialog from "@/my-library/components/upload-book-dialog";

import { quizlists } from "./data/quiz-lists";
import "./styles.css";
import { PlusCircle } from "lucide-react";

import { Button } from "@/components/ui/button";
import { BooksLibrary } from "./components/books/books-library";
import { TextbooksLibrary } from "./components/textbooks/textbooks-library";
import { DocumentsLibrary } from "./components/documents/documents-library";
import { NotesLibrary } from "./components/notes/notes-library";
import { uploadBook } from "@/api/books";
import UploadingDocs  from "@/my-library/components/uploading-docs";

export const metadata = {
  title: "books App",
  description: "Example books app using the components.",
};

export default function MyLibraryPage() {
  const [isOpen, setIsOpen] = useState(false); // Local state to control dialog
  
  const handleClose = () => {
    setIsOpen(false);
  };

  const startUpload = async (
    params: UploadBookParams,
  ) => {
    const { file, title, category, onUploadProgress } = params;
    if (file && title) {
      try {
        console.log("api response: ", file, title, category);
        const response = await uploadBook(file, title, category, onUploadProgress);
        console.log("api response: ", response);
        handleClose();
      } catch (error) {
        console.error("Error uploading the book", error);
      }
    }
  };
 
  return (
    <>
      <div className="border-t">
        <div className="bg-background">
          <div className="grid lg:grid-cols-5">
            <Sidebar quizlists={quizlists} className="hidden lg:block" />
            <div className="col-span-3 lg:col-span-4 lg:border-l">
              <div className="h-full px-4 py-6 lg:px-8">
                <Tabs defaultValue="books" className="h-full space-y-6">
                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                    <TabsList>
                      <TabsTrigger value="books" className="relative">
                        Books
                      </TabsTrigger>
                      <TabsTrigger value="textbooks">Textbooks</TabsTrigger>
                      <TabsTrigger value="documents">Documents</TabsTrigger>
                      <TabsTrigger value="notes">Notes</TabsTrigger>
                    </TabsList>
                    <div className="mt-4 sm:mt-0 mr-auto sm:ml-auto sm:mr-4">
    <Button className="bg-violet-900 hover:bg-white hover:text-violet-900 text-sm sm:text-base h-8 sm:h-auto w-auto sm:w-auto" onClick={() => setIsOpen(true)}>
      <PlusCircle className="mr-2 h-4 w-4" />
      Add file
    </Button>
    <UploadBookDialog
      onUpload={startUpload}
      isOpen={isOpen}
      onClose={handleClose}
    />
  </div>
                  </div>
                  <TabsContent
                    value="books"
                    className="border-none p-0 outline-none"
                  >
                    {listenNowBooks.length > 0 ? (
                      <BooksLibrary />
                    ) : (
                      <BookEmptyPlaceholder />
                    )}
                  </TabsContent>
                  <TabsContent
                    value="textbooks"
                    className="h-full flex-col border-none p-0 data-[state=active]:flex"
                  >
                    {listenNowBooks.length > 0 ? (
                      <TextbooksLibrary />
                    ) : (
                      <TextbookEmptyPlaceholder />
                    )}
                  </TabsContent>
                  <TabsContent
                    value="documents"
                    className="h-full flex-col border-none p-0 data-[state=active]:flex"
                  >
                    {listenNowBooks.length > 0 ? (
                      <DocumentsLibrary />
                    ) : (
                      <DocumentEmptyPlaceholder />
                    )}
                  </TabsContent>
                  <TabsContent
                    value="notes"
                    className="h-full flex-col border-none p-0 data-[state=active]:flex"
                  >
                    {listenNowBooks.length > 0 ? (
                      <NotesLibrary />
                    ) : (
                      <NotesEmptyPlaceholder />
                    )}
                  </TabsContent>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
