import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@/components/theme-context";
import { AuthWrapper } from "./authentication/auth-wrapper";
import { MainApp } from "./components/main-app";

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthWrapper>
        
          <MainApp/>
          
        </AuthWrapper>
      </ThemeProvider>
    </Router>
  );
}

export default App;
