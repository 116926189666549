import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { madeForYouBooks } from "@/my-library/data/books";
import { AlbumArtwork } from "@/my-library/components/books/books-artwork";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { fetchUserBooks, deleteBook } from "@/api/books";
import { Book } from "@/models/Book";
import ChatModal from "@/chatbot/chat-modal";
import { Badge } from "@/components/ui/badge";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Trash2 } from "lucide-react";
import { useAuthContext } from '@/authentication/auth-wrapper'


export function BooksLibrary() {
  const [books, setBooks] = useState<Book[]>([]);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [selectedBookTitle, setSelectedBookTitle] = useState<string | null>(
    null
  );
  const [selectedBookId, setSelectedBookId] = useState<number | null>(null);
  const [open, setOpen] = useState(false); // Add this state to control the dialog open/close
  const [toBeDeleted, setToBeDeleted] = useState<number | null>(null); // Store the book ID that could potentially be deleted
  const { user } = useAuthContext();

  const handleDeleteClick = (e: React.MouseEvent, id: number) => {
    e.stopPropagation(); // Prevent triggering the AlbumArtwork onClick event
    setToBeDeleted(id);
    setOpen(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = async () => {
    // Actual delete operation
    try {
      if (toBeDeleted !== null) {
        await deleteBook(toBeDeleted);
        // Remove the deleted book from the state.
        setBooks(books.filter((b) => b.id !== toBeDeleted));
        setOpen(false); // Close the dialog
      }
    } catch (error) {
      console.error("Failed to delete the book:", error);
    }
  };

  useEffect(() => {
    const getBooks = async () => {
      if (user) {
        // make sure user is not null before fetching books
        try {
          const booksFromApi = await fetchUserBooks(user.id);
          setBooks(booksFromApi);
        } catch (error) {
          console.error(error);
          // You might want to set an error state here to show a message in your UI
        }
      }
    };
    getBooks();
  }, [user]); // add user to the dependency array to re-run the effect when user changes

  // Get placeholder image URL based on book file type
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h2 className="text-2xl font-semibold tracking-tight">Chat Now</h2>
          <p className="text-sm text-muted-foreground">
            Start or resume a chat below.
          </p>
        </div>
      </div>
      <Separator className="my-4" />
      <div className="relative">
        <ScrollArea>
          <div className="flex space-x-4 pb-4">
            {books
              .filter((book) => {
                console.log(
                  `Book title: ${book.title}, category: ${book.category}`
                );
                return book.category === "Book";
              })
              .map((book) => (
                <div className="relative">
                  <AlbumArtwork
                    key={book.id}
                    book={book}
                    onClick={() => {
                      setSelectedBookTitle(book.title);
                      setSelectedBookId(book.id); // Set the selectedBookId state here
                      setIsChatModalOpen(true);
                    }}
                    className="w-[200px]"
                    aspectRatio="portrait"
                    width={250}
                    height={330}
                    action={
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={(e) => handleDeleteClick(e, book.id)}
                        className="flex items-center justify-center"
                      >
                        <Trash2 className="h-4 w-4" color="#c70a0a" />
                      </Button>
                    }
                  />
                  
                </div>
              ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger></AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to delete this item?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              item and remove the data from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmDelete}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <div className="mt-6 space-y-1">
        <h2 className="text-2xl font-semibold tracking-tight">
          Test your knowledge
          <Badge className="ml-2" variant="announcement">Coming Soon!</Badge>
        </h2>
        <p className="text-sm text-muted-foreground">
          Your personal Quiz List.
        </p>
      </div>
      <Separator className="my-4" />
      <div className="relative">
        <ScrollArea>
          <div className="flex space-x-4 pb-4">
            {madeForYouBooks.map((book) => (
              <AlbumArtwork
                key={book.title}
                book={book}
                className="w-[150px]"
                aspectRatio="square"
                width={150}
                height={150}
              />
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
      <ChatModal
        isOpen={isChatModalOpen}
        closeModal={() => setIsChatModalOpen(false)}
        bookTitle={selectedBookTitle}
        book_id={selectedBookId}
      />
    </>
  );
}
